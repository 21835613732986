import tools from "../../../extends/tools";
export default {
    selectedUnit: [],
    skuid: 0,
    dataInit: null,
    goodsList: [],
    baseUrl: tools.baseURL(),
    uploadUrl: tools.baseURL() + 'api/admin/file',
    authorizationBearer: tools.getToken(),
    createShareFlag: false,
    enclosure: [],
    sumData: {
        sumNum: 0,
        sumPrice: 0,
        allSumPrice: 0,
    },
    saveDataInit: null,
    //
    source: {},
    isRead: false,
    saveData: {
        name: '',
        goods: ''
    },
    showData: {
        value: {}
    },
    // 单位列表
    assist_unit: []
}