<template>
    <div class="container" :style="{left:selectSide==='left'?'75px':'0',right:selectSide==='right'?'75px':'0'}" style="overflow:auto;">
        <div v-if="skuid>0" style="padding:20px 0px;width:95%;position:relatvie;margin:0 auto;" :style="{height:__tableHeight__+50+'px'}">
            <div style="text-align:center;position:relative;margin-bottom:30px;">
                <span style="font-size:26px;">单位详情</span>
            </div>
            <!-- 顶部导航 -->
            <el-form ref="form"  label-width="95px">
                <el-form-item label="名称">
                    <label for="">{{showData.name}}</label>
                </el-form-item>
                <el-form-item label="类型名称">
                    <label for="">{{showData.type_name}}</label>
                </el-form-item>
                <el-form-item label="数量">
                    <label for="">{{showData.value.num}}</label>
                </el-form-item>
                <el-form-item label="单位名称">
                    <label for="">{{showData.value.unit_name}}</label>
                </el-form-item>
            </el-form>

            <el-form ref="form"  label-width="95px">
               <el-form-item label="商品名称">
                    <label for="">{{showData.value.goods_name}}</label>
                </el-form-item>
                <el-form-item label="sku名称">
                    <label for="">{{showData.value.sku.join(' ， ')}}</label>
                </el-form-item>
                <el-form-item label="sku图片">
                    <label for=""><img style="width:200px;max-height:600px;" :src="baseUrl+showData.value.sku_pic" /></label>
                </el-form-item>
                <el-form-item label="单位数量">
                    <label for="">{{showData.value.assist.num}}</label>
                </el-form-item>
            </el-form>

        </div>
        
        <div v-if="skuid === 0" style="padding:20px 0px;width:95%;position:relatvie;margin:0 auto;" :style="{height:__tableHeight__+50+'px'}">
            <div style="text-align:center;position:relative;margin-bottom:30px;">
                <span style="font-size:26px;">辅佐单位</span>
            </div>
            <div class="attr_row" style="margin:0 0 30px 0;">
                <table class="mytable" style="width:95%;">
                    <thead class="thead">
                        <tr class="rows ">
                            <td class="cols" >
                                <i class="el-icon-s-tools" style="font-size:16px;"></i>
                            </td>
                            <td class="cols">序号</td>
                            <td class="cols">名称</td>
                            
                            <td class="cols">
                                <tr><td colspan="2">基础单位</td></tr>
                                <!-- 输入数量 -->
                                <tr>
                                    <td class="second_cols">
                                        数量
                                    </td>
                                    <td class="second_cols">
                                        单位
                                    </td>
                                </tr>
                            </td>

                            <td class="cols" v-for="(i,k) in assist_unit" :key="k">
                                <tr><td colspan="2">辅助单位 {{k+1}}
                                <i class="el-icon-remove-outline"
                                    @click="deleteUnit(k)"
                                    style="color:red;font-size:18px;margin-left:10px;cursor: pointer;"></i>    
                                 </td></tr>
                                <tr>
                                    <td class="second_cols">
                                        输入数量
                                    </td>
                                    <td class="second_cols">
                                        选择单位
                                    </td>
                                </tr>
                            </td>

                            <td class="cols">
                                <i class="el-icon-plus" @click="addUnit"  style="color:blue;font-size:18px;cursor:pointer;"></i>
                            </td>
                        </tr>
                    </thead>
                    <tbody class="tbody" v-if="goodsList.length > 0">
                        <tr class="rows purchase" :class="{odd:k%2===0}"  v-for="(i,k) in goodsList" :key="k">
                            <td class="cols" style="width:100px;">
                                <i class="el-icon-circle-plus" 
                                @click="pickGoods"
                                style="color:red;font-size:18px;cursor: pointer;"></i>
                                <i class="el-icon-remove-outline"
                                @click="removeLine(k)"
                                style="color:red;font-size:18px;margin-left:10px;cursor: pointer;"></i>
                            </td>
                            <td class="cols"> 
                                {{k}}
                            </td>
                            <td class="cols"> 
                                {{i.name}}
                            </td>
                            <td class="cols">
                                <tr>
                                    <td class="second_cols">
                                       {{i.num}}
                                    </td>
                                    <td class="second_cols">
                                        {{i.unit_name}}
                                    </td>
                                </tr>
                            </td>

                            <td class="cols" v-for="(item,key) in i.assist_unit" :key="key">
                                <tr>
                                    <td class="second_cols" style="width:170px;">
                                        <el-input-number size="mini" v-model="item.num" style="width:100px;"></el-input-number>
                                        <b style="color:blue;"> {{i.unit_name}} =</b>
                                    </td>
                                    <td class="second_cols">
                                        <el-select v-model="item.unit" size="mini" @change="changeUnit(i,item,key)">
                                            <el-option v-for="(ii,kk) in cleanUnit(source.goods_unit,i,key)"
                                            :key="kk" 
                                            :value="ii.id" 
                                            :label="ii.name"></el-option>
                                        </el-select>
                                    </td>
                                </tr>
                            </td>
                            <td></td>
                        </tr>
                    </tbody>
                    <tbody class="tbody" v-else>
                        <tr class="rows purchase" >
                            <td class="cols" colspan="11" style="text-align:center;">
                                暂无数据 <i class="el-icon-circle-plus" @click="pickGoods" style="color:red;font-size:18px;cursor:pointer;"></i>
                            </td>
                        </tr>
                    </tbody>
                    
                </table>
            </div>
        </div>

        <!-- 悬浮条 -->
        <div class="bottom_bar" v-if="skuid === 0">
            <div style="width:90%;margin:0 auto;position:relative;">
                <div style="position:absolute;right:0px;top:0px;">
                    <!-- <el-button>取消</el-button> -->
                    <el-button type="primary" @click="save(0)">保存</el-button>
                </div>
            </div>
        </div>

        <!-- 选择商品 -->
        <el-dialog
            title="选择商品"
            :visible.sync="createShareFlag"
            :modal="false"
            :close-on-click-modal="false"
            v-if="createShareFlag"
            width="1180px"
            center>
            <createShare 
                :storeId="saveData.warehouse_id"
                :borrowId="saveData.borrowId"
                @saveSelect="saveSelect"
                @fleshList="fleshList" @shareExit="shareExit"></createShare>
        </el-dialog>

    </div>
</template>

<script>
import createShare from './creatDialog';
import shareListFlag from './listDialog';
import apis from "../../common/js/api2";
import tools from "../../../extends/tools";
import datas from "./objectData";
import methods from "./method";

export default {
    props:{
        
    },
    inject: ['selectSide'], 
    data(){
        return datas;
    },
    destroyed(){
        this.goodsList = [];
        this.enclosure = [];
        this.assist_unit = [];
        this.sumData = {
            sumNum: 0,
            sumPrice: 0,
            allSumPrice: '0.00',
        };
        this.saveData = tools.copy(this.saveDataInit);
    },
    components:{
        createShare,shareListFlag
    },
    methods:methods,
    created(){
        if(this.saveDataInit === null){
            this.saveDataInit = tools.copy(this.saveData);
        }
        //初始化数据
        this.init();
    }
}
</script>

<style scoped lang="less">
@import url('../../../assets/less/normal');
.bottom_bar{
    min-width:100%;height:55px;background:#fff;position:fixed;line-height: 55px;
    bottom:0px;left:0px;z-index: 99;line-height:55px;text-align: left;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);border:1px solid #EBEEF5;
    .button{
        font-size:16px;margin-left:30px;z-index: 99;top:0px;width:130px;
    }
}
.el-input__inner{
    width:100%;background: #fff !important;;
}
.rows.purchase .cols{
    height: 40px;
}
.second_cols{
    width:140px;text-align: center;
}

</style>