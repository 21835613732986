import tools from "../../../extends/tools";
import apis from "../../common/js/api2";
export default {
    // 增加一栏辅助单位
    addUnit() {
        if (this.goodsList.length <= 0) {
            this.$message({
                type: 'error',
                message: '请先添加商品'
            })
            return;
        }
        //先给上方计数器加一个
        this.assist_unit.push(1);
        // 再给每个商品中商品增加变量
        this.goodsList.forEach(i => {
            i.assist_unit.push({
                num: 1,
                unit: ''
            });
        })
    },
    // 
    deleteUnit(key) {
        if (this.goodsList.length <= 0) {
            this.$message({
                type: 'error',
                message: '请先添加商品'
            })
            return;
        }
        this.assist_unit.splice(key, 1);
        this.goodsList.forEach(i => {
            i.assist_unit.splice(key, 1);
        })
    },
    init() {
        //获取数据源
        apis.getGoodsGroupsSource().then(res => {
            if (tools.msg(res, this)) {
                for (let i in res.data) {
                    if (res.data[i]) {
                        this.$set(this.source, i, res.data[i]);
                    } else {
                        this.$set(this.source, i, []);
                    }
                }
            }
        }).catch(err => {
            tools.err(err, this);
        });
        //如果是编辑，则获取详情
        let id = this.$route.query.id;
        this.skuid = parseInt(id);
        if (id > 0) {
            //查看详情禁止编辑
            if (this.$route.query.read == 1) {
                this.isRead = true;
            }
            apis.getGoodsGroupsDetail({
                id: id
            }).then(res => {
                if (tools.msg(res, this)) {
                    this.initData(res.data);
                }
            }).catch(err => {
                tools.err(err, this);
            });
        }


    },
    initData(data) {
        this.showData = data;
    },
    removeLine(key) {
        // if (this.goodsList.length <= 1) {
        //     return;
        // }
        this.goodsList.splice(key, 1);
        this.changeSum();
    },
    // 获取上传的地址
    uploadFile(file) {
        if (file.code === 200) {
            this.enclosure.push(file.font);
        } else {
            this.$message({
                type: 'error',
                message: file.msg
            });
        }
    },
    fleshList() {

    },
    changePrice(item) {
        item.sum = tools.toDecimal2(item.retail_price * item.num);
        this.changeSum();
    },
    changeSum() {
        this.sumData.sumNum = 0;
        this.sumData.allSumPrice = 0;
        this.sumData.sumPrice = 0;
        this.goodsList.forEach((item, key) => {
            this.sumData.sumNum += item.num;
            console.log((item.retail_price));
            this.sumData.sumPrice += parseFloat(item.retail_price) * item.num;

        });
        this.sumData.allSumPrice = tools.toDecimal2(this.sumData.sumPrice + parseFloat(this.saveData.additional_amount_copy));
        this.sumData.sumPrice = tools.toDecimal2(this.sumData.sumPrice);
    },
    saveSelect(selectData) {
        // console.log('selectData', selectData);

        if (selectData.length <= 0) {
            return;
        }
        // debugger
        selectData.forEach((item, key) => {
            let obj = {};
            obj.name = item.goods_info.name + ' - ' + item.disposeAll;
            obj.goods_specifications_id = item.id;
            obj.unit = parseInt(item.goods_info.unit_id);
            obj.unit_name = item.goods_info.unit_info.name;

            obj.num = 1;
            // 判断是否已经有辅助单位了，有的话复制原有行的辅助单位
            if (this.goodsList.length > 0) {
                let u = this.goodsList[0];
                // 复制单位
                obj.assist_unit = tools.copy(u.assist_unit);
            } else {
                obj.assist_unit = [];
            }
            // console.log(obj);
            //判断是否有有相同的
            let flag = true;
            this.goodsList.forEach((i, k) => {
                if (!!i.goods_specifications_id && item.id === i.goods_specifications_id) {
                    i.num = i.num + 1;
                    flag = false;
                    this.$message({
                        type: 'error',
                        message: '有相同商品，已合并到列表中'
                    })
                }
            });
            //
            console.log(obj);
            if (flag) {
                this.goodsList.push(obj);
            }
        });

        this.changeSum();
        // console.log(this.goodsList);
    },
    cleanUnit(unitArr, item, index) {
        // 排除item中默认的单位
        // 排除item.assist_unit里的单位
        let arr = [];
        unitArr.forEach((i, k) => {
            if (i.id !== item.unit) {
                arr.push(i);
            }
        });
        // let newArr = [];
        // arr.forEach((i, k) => {
        //     if (this.inUnit(i.id, item.assist_unit, index)) {
        //         newArr.push(i);
        //     }
        // })

        return arr;
    },
    changeUnit(i, item, key) {
        i.assist_unit.forEach((ii, kk) => {
            if (kk !== key && ii.unit === item.unit) {
                this.$message({
                    type: 'error',
                    message: '本行已存在相同单位,请重新选择'
                });
                item.unit = '';
            }
        });


    },

    inUnit(unit_id, assist_unit, index) {
        let flag = true;
        let flag2 = false;
        assist_unit.forEach((i, k) => {
            if (index === k) {
                flag2 = true;
            }
            if (unit_id === i.unit) {
                flag = false;
            }
        })

        return flag || flag2;
    },

    shareExit() {
        this.createShareFlag = false;
    },
    pickGoods() {
        if (this.saveData.warehouse_id === '') {

            // this.$message({
            //     type: 'error',
            //     message: '请先选择一个仓库'
            // });
            // return;
        }

        this.createShareFlag = true;
    },
    save(status) {
        this.saveData = {};
        //转换列表
        let goods = [];

        this.goodsList.forEach((item, key) => {
            let obj = {
                skuid: item.goods_specifications_id,
                num: item.num,
                unit: item.unit,
                assist: tools.copy(item.assist_unit)
            };
            goods.push(obj);
        });
        //
        this.saveData.assist = JSON.stringify(goods);

        let loader = tools.loading(null, this);
        //获取详情
        let id = this.$route.query.id;
        if (id > 0) {
            this.saveData.id = id;
            apis.updateGoodsGroups(this.saveData, 2).then(res => {
                if (tools.msg(res, this)) {
                    this.$message({
                        type: 'success',
                        message: '修改成功'
                    })
                }
                tools.loading(loader);
            }).catch(err => {
                tools.err(err, this);
                tools.loading(loader);
            });

            return;
        }

        apis.addGoodsGroups(this.saveData, 2).then(res => {
            if (tools.msg(res, this)) {
                this.$message({
                    type: 'success',
                    message: '添加成功！'
                })
                this.$router.push({
                    path: '/goods/combination'
                });
            }
            tools.loading(loader);
        }).catch(err => {
            tools.err(err, this);
            tools.loading(loader);
        });
    }
}